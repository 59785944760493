import { useMutation } from '@tanstack/react-query';
import { useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCrmCall } from '../../../../api/useCrmCall';
import { useAmountLimits } from '../../../../hooks/useAmountLimits';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import useDisableScroll from '../../../../hooks/useDisableScroll';
import { usePaymentMethods } from '../../../../hooks/usePaymentMethods';
import { usePrimaryMethods } from '../../../../hooks/usePrimaryMethods';
import { closeAllModals } from '../../../../redux/slices/modalSlice';
import { setNotifications } from '../../../../redux/slices/notificationsSlice';
import { clearState, setDepositAmount, setPaymentMethod } from '../../../../redux/slices/paymentSlice';
import { getIsFlagEnabled } from '../../../../utils/getIsFlagEnabled';
import { toMoneyFormat } from '../../../../utils/toMoneyFormat';
import { Button } from '../../../atoms/Button/Button';
import { ContentSwitcher } from '../../../atoms/ContentSwitcher/ContentSwitcher';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import { NumberInput } from '../../../molecules/NumberInput/NumberInput';
import { PaymentMethods } from '../../../organisms/PaymentMethods/PaymentMethods';
import styles from './Deposit.module.scss';

const PageMeta = () => (
  <Helmet>
    <meta name='robots' content='noindex, nofollow' />
  </Helmet>
);

export const Deposit = ({ closePortal }) => {
  const modalRef = useRef();
  const { accessToken } = useSelector(store => store.userSlice);
  const { paymentMethods, depositAmount, paymentMethod, primaryMethod, crypto } = useSelector(
    store => store.paymentSlice,
  );
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation('Deposit');
  const { postCrmRequest } = useCrmCall();
  const { primaryMethods, handleSwitchPrimaryMethods } = usePrimaryMethods();

  usePaymentMethods();
  useDisableScroll();

  const { payinLimit } = useAmountLimits(paymentMethods);

  const initDeposit = useMutation({
    mutationFn: () =>
      postCrmRequest('/payment/init_payment_request', {
        method: paymentMethod,
        amount: depositAmount,
        network: crypto?.network,
        currency: crypto?.currency,
        redirectUrl: window?.location?.origin,
      }),
    onSuccess: data => (document.location.href = data),
    onError: () => {
      dispatch(clearState());
    },
  });

  const handleButtonClick = useCallback(() => {
    if (primaryMethod === 'CRYPTO') {
      dispatch(setPaymentMethod(primaryMethod));
      dispatch(setDepositAmount(payinLimit));
    }

    if (primaryMethod === 'Fiat' && Number(depositAmount) < payinLimit) {
      dispatch(
        setNotifications({
          type: 'error',
          message: 'LESS_THAN_MINIMAL_SUM',
          params: { amount: payinLimit },
        }),
      );
    } else if (accessToken) {
      initDeposit.mutate();
    }
  }, [depositAmount, paymentMethod]);

  const handleAmountChange = e => {
    const value = toMoneyFormat(e.target.value);

    if (value !== '') {
      dispatch(setDepositAmount(value));
    } else {
      dispatch(setDepositAmount(''));
    }
  };

  const handleClosePortal = () => {
    // Если флоу через старую модалку по прямому урл
    if (pathname === '/deposit') {
      navigate('/');
      return dispatch(closeAllModals());
    }

    dispatch(clearState());
    closePortal();
  };

  useClickOutside(modalRef, handleClosePortal);

  return (
    <>
      <PageMeta />
      <ModalOverlay overlayClose={!getIsFlagEnabled('NEW_MODALS')}>
        <div className={styles.modal} ref={modalRef}>
          <ModalHeader
            title='deposit'
            closePortal={getIsFlagEnabled('NEW_MODALS') ? handleClosePortal : null}
            showBackArrow={false}
          />
          <div className={styles.wrapper}>
            <div className={styles.deposit}>
              <ContentSwitcher active='Deposit' />
              <ContentSwitcher
                active={primaryMethod}
                buttonsMap={primaryMethods}
                handleClick={handleSwitchPrimaryMethods}
                variant='secondary'
              />
              {primaryMethod === 'Fiat' ? (
                <>
                  <NumberInput
                    labelText={t('labelText')}
                    name='amount'
                    onChange={handleAmountChange}
                    value={depositAmount}
                  />
                  <PaymentMethods header={t('header')} fiatMethods={paymentMethods} minBadge />
                  <Button
                    disabled={!depositAmount || !paymentMethod}
                    buttonText={t('buttonText')}
                    onClick={handleButtonClick}
                  />
                </>
              ) : (
                <>
                  <PaymentMethods header={t('header')} cryptoMethods={paymentMethods?.networkList} />
                  <Button
                    disabled={primaryMethod === 'Fiat' || !paymentMethod}
                    buttonText={t('buttonText')}
                    onClick={handleButtonClick}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </ModalOverlay>
    </>
  );
};
