import { MainPage } from '../../components/pages/MainPage/MainPage';

export const PRIVATE_ROUTES = {
  profile: {
    path: '/profile/*',
    element: MainPage,
  },
  deposit: {
    path: '/deposit',
    element: MainPage,
  },
  withdrawal: {
    path: '/withdrawal/*',
    element: MainPage,
  },
  safe: {
    path: '/safe',
    element: MainPage,
  },
};
