import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useClickOutside } from '../../../../../hooks/useClickOutside';
import useDisableScroll from '../../../../../hooks/useDisableScroll';
import { openCommonModal } from '../../../../../redux/slices/commonModalSlice';
import { getIsFlagEnabled } from '../../../../../utils/getIsFlagEnabled';
import ModalOverlay from '../../../../atoms/ModalOverlay/ModalOverlay';
import ModalHeader from '../../../../molecules/ModalHeader/ModalHeader';
import { useWithdrawalClose } from '../useWithdrawalClose';

const PageMeta = () => (
  <Helmet>
    <meta name='robots' content='noindex, nofollow' />
  </Helmet>
);

export const WithdrawalBase = ({
  closePortal,
  title,
  backTo,
  showBackArrow = true,
  children,
  className,
  portalType = 'withdrawal',
}) => {
  const modalRef = useRef();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const handleClosePortal = useWithdrawalClose(closePortal);

  useDisableScroll();

  const goBack =
    pathname === '/withdrawal' || !getIsFlagEnabled('NEW_MODALS')
      ? null
      : () => dispatch(openCommonModal({ type: backTo }));

  useClickOutside(modalRef, () => handleClosePortal(portalType));

  return (
    <>
      <PageMeta />
      <ModalOverlay overlayClose={!getIsFlagEnabled('NEW_MODALS')}>
        <div className={className} ref={modalRef}>
          <ModalHeader
            title={title}
            closePortal={getIsFlagEnabled('NEW_MODALS') ? () => handleClosePortal(portalType) : null}
            onBack={goBack}
            showBackArrow={showBackArrow}
          />
          {children}
        </div>
      </ModalOverlay>
    </>
  );
};
