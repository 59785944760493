import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { PrivateRoute } from '../components/atoms/PrivateRoute/PrivateRoute';
import { PRIVATE_ROUTES } from '../constants/routes/private';
import { PUBLIC_ROUTES } from '../constants/routes/public';

const AppRoutes = () => {
  const { accessToken } = useSelector(store => store.userSlice);

  const renderPrivateRoutes = () => {
    return Object.values(PRIVATE_ROUTES).map(({ path, element: Element }) => (
      <Route key={path} path={path} element={accessToken ? <Element /> : <PrivateRoute />} />
    ));
  };

  const renderPublicRoutes = () => {
    return Object.values(PUBLIC_ROUTES).map(({ path, element: Element }) => (
      <Route key={path} path={path} element={<Element />} />
    ));
  };

  return (
    <Routes>
      {renderPrivateRoutes()}
      {renderPublicRoutes()}
      <Route path='*' element={<Navigate to='/404' replace />} />
    </Routes>
  );
};

export default AppRoutes;
