import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useCrmCall } from '../../../../../api/useCrmCall';
import { openCommonModal } from '../../../../../redux/slices/commonModalSlice';
import { openModal } from '../../../../../redux/slices/modalSlice';
import { setNotifications } from '../../../../../redux/slices/notificationsSlice';
import { setPageFrom } from '../../../../../redux/slices/paymentSlice';
import { getIsFlagEnabled } from '../../../../../utils/getIsFlagEnabled';
import { Button } from '../../../../atoms/Button/Button';
import { BanksSelect } from '../../../../molecules/BanksSelect/BanksSelect';
import { WithdrawalBase } from '../WithdrawalBase/WithdrawalBase';
import { useInputByMethod } from './useInputByMethod';
import styles from './WithdrawalRequisitesAdd.module.scss';

export const WithdrawalRequisitesAdd = ({ closePortal }) => {
  const { accessToken } = useSelector(store => store.userSlice);
  const { paymentMethod, requisiteNumber, bankName, primaryMethod, crypto } = useSelector(store => store.paymentSlice);
  const { postCrmRequest } = useCrmCall();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { t } = useTranslation('Withdrawal');
  const { t: tCrypto } = useTranslation('Crypto');
  const inputComponent = useInputByMethod();

  const checkRequisiteMutation = useMutation({
    mutationFn: data => postCrmRequest('/payment/payout_check_add_requisite', data),
    // { actionType: 'add_requisite' } - хардкод, который потом надо будет исправлять если модалка появится ещё где-то
    onSuccess: () => smsMutation.mutate({ actionType: 'add_requisite' }),
  });
  const smsMutation = useMutation({
    mutationFn: state => postCrmRequest('/payment/send_verification_sms', state),
    onSuccess: () => {
      dispatch(setPageFrom('withdrawal'));
      if (pathname === '/withdrawal' || !getIsFlagEnabled('NEW_MODALS')) {
        return dispatch(
          openModal({
            route: '/withdrawal-step5',
          }),
        );
      }
      dispatch(openCommonModal({ type: 'withdrawalStep5' }));
    },
  });

  const data = {
    reqType: primaryMethod === 'CRYPTO' ? primaryMethod : paymentMethod,
    requisiteNumber,
    bankName,
    network: crypto?.network,
    currency: crypto?.currency,
  };

  const handleSubmit = () => {
    if (paymentMethod === 'MC/VISA/MIR P2P RUB' && requisiteNumber.length < 16) {
      dispatch(setNotifications({ type: 'error', message: 'ERROR_CARD_NUMBER_LENGTH' }));
      return;
    }

    if (paymentMethod === 'A2A RUB' && requisiteNumber.length < 20) {
      dispatch(setNotifications({ type: 'error', message: 'ERROR_ACC_NUMBER_LENGTH' }));
      return;
    }

    if (accessToken) {
      checkRequisiteMutation.mutate(data);
    }
  };

  return (
    <WithdrawalBase
      closePortal={closePortal}
      title={crypto?.network ? tCrypto(`${paymentMethod}.methodName`) : t(`${paymentMethod}.methodName`)}
      className={styles.modal}
      backTo='withdrawal'
    >
      <div className={styles.wrapper}>
        <>
          <label className={styles.cardLabel} htmlFor='requisiteNumber'>
            {crypto?.network ? tCrypto(`${paymentMethod}.methodName`) : t(`${paymentMethod}.label`)}
          </label>
          {inputComponent}
          {primaryMethod === 'Fiat' && <BanksSelect />}
          {primaryMethod === 'CRYPTO' && (
            <p className={styles.cardNote}>
              Внимательно проверяйте сеть {crypto?.network} и адрес. Ошибка при добавлении кошелька может привести к
              потере средств.
            </p>
          )}
          <Button
            buttonText='Продолжить'
            disabled={primaryMethod === 'Fiat' ? !bankName || !requisiteNumber : !requisiteNumber}
            isLoading={checkRequisiteMutation.isPending || smsMutation.isPending}
            onClick={handleSubmit}
          />
        </>
      </div>
    </WithdrawalBase>
  );
};
