import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useAmountLimits } from '../../../../../hooks/useAmountLimits';
import { usePaymentMethods } from '../../../../../hooks/usePaymentMethods';
import { usePrimaryMethods } from '../../../../../hooks/usePrimaryMethods';
import { openCommonModal } from '../../../../../redux/slices/commonModalSlice';
import { openModal } from '../../../../../redux/slices/modalSlice';
import { setNotifications } from '../../../../../redux/slices/notificationsSlice';
import { setWithdrawalAmount } from '../../../../../redux/slices/paymentSlice';
import { getIsFlagEnabled } from '../../../../../utils/getIsFlagEnabled';
import { toMoneyFormat } from '../../../../../utils/toMoneyFormat';
import { Button } from '../../../../atoms/Button/Button';
import { ContentSwitcher } from '../../../../atoms/ContentSwitcher/ContentSwitcher';
import { NumberInput } from '../../../../molecules/NumberInput/NumberInput';
import { PaymentMethods } from '../../../../organisms/PaymentMethods/PaymentMethods';
import { WithdrawalBase } from '../WithdrawalBase/WithdrawalBase';
import styles from './Withdrawal.module.scss';

export const Withdrawal = ({ closePortal }) => {
  const { paymentMethods, withdrawalAmount, paymentMethod, primaryMethod } = useSelector(store => store.paymentSlice);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { t } = useTranslation('Withdrawal');
  const { primaryMethods, handleSwitchPrimaryMethods } = usePrimaryMethods();

  usePaymentMethods();

  const { payoutLimit } = useAmountLimits(paymentMethods);

  const handleChangeAmount = e => {
    const value = toMoneyFormat(e.target.value);

    if (value !== '') {
      dispatch(setWithdrawalAmount(value));
    } else {
      dispatch(setWithdrawalAmount(''));
    }
  };

  const handleButtonClick = useCallback(() => {
    if (Number(withdrawalAmount) < payoutLimit || Number(withdrawalAmount) <= 0) {
      dispatch(
        setNotifications({
          type: 'error',
          message: 'LESS_THAN_MINIMAL_SUM_WITHDRAWAL',
          params: { amount: payoutLimit },
        }),
      );
    } else {
      if (pathname === '/withdrawal' || !getIsFlagEnabled('NEW_MODALS')) {
        return dispatch(
          openModal({
            route: '/withdrawal-step2',
          }),
        );
      }
      dispatch(openCommonModal({ type: 'withdrawalStep2' }));
    }
  }, [withdrawalAmount, paymentMethod]);

  return (
    <WithdrawalBase closePortal={closePortal} title='withdrawal' showBackArrow={false} className={styles.modal}>
      <div className={styles.wrapper}>
        <div className={styles.withdrawal}>
          <ContentSwitcher active='Withdrawal' />
          <ContentSwitcher
            active={primaryMethod}
            buttonsMap={primaryMethods}
            handleClick={handleSwitchPrimaryMethods}
            variant='secondary'
          />
          {primaryMethod === 'Fiat' ? (
            <>
              <NumberInput
                labelText={t('labelText')}
                name='amount'
                value={withdrawalAmount}
                onChange={handleChangeAmount}
              />
              <PaymentMethods header={t('header')} fiatMethods={paymentMethods} />
              <Button
                buttonText={t('buttonText')}
                disabled={!withdrawalAmount || !paymentMethod || withdrawalAmount === '0'}
                onClick={handleButtonClick}
              />
            </>
          ) : (
            <>
              <NumberInput
                labelText={t('labelText')}
                name='amount'
                value={withdrawalAmount}
                onChange={handleChangeAmount}
              />
              <PaymentMethods header={t('header')} cryptoMethods={paymentMethods?.networkList} />
              <Button
                buttonText={t('buttonText')}
                disabled={!withdrawalAmount || !paymentMethod || withdrawalAmount === '0'}
                onClick={handleButtonClick}
              />
            </>
          )}
        </div>
      </div>
    </WithdrawalBase>
  );
};
