import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { useCrmCall } from '../../../../api/useCrmCall';
import useDisableScroll from '../../../../hooks/useDisableScroll';
import { openAdditionModal } from '../../../../redux/slices/additionalModalSlice';
import { setEmail } from '../../../../redux/slices/emailModalSlice';
import { setNotifications } from '../../../../redux/slices/notificationsSlice';
import { getIsFlagEnabled } from '../../../../utils/getIsFlagEnabled';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import { InputWithButton } from '../../../molecules/InputWithButton/InputWithButton';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import styles from './Settings.module.scss';
import { ThemeSwitcher } from './ui/ThemeSwitcher';

const PageMeta = () => (
  <Helmet>
    <meta name='robots' content='noindex, nofollow' />
  </Helmet>
);

const emailRegex =
  /^(?!(?:[^@]*@){2})(?![.-])(?!.*[.-]$)(?!.*\.\.)(?!.*--.*)[a-zA-Z0-9][a-zA-Z0-9.-]{0,63}@(?![.-])(?!.*[.-]$)[a-zA-Z0-9-]{1,63}\.[a-zA-Z]{2,6}$/;

export const Settings = () => {
  const [birthDate, setBirthDate] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const { crmUserData, user, accessToken } = useSelector(store => store.userSlice);
  const { isEmailAdded } = useSelector(store => store.emailModalSlice);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { postCrmRequest } = useCrmCall();

  useDisableScroll();

  const setBirthdateMutation = useMutation({
    mutationFn: date => postCrmRequest('/user/set_date', { birthDate: date }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['userData'] }),
  });

  const handleChangeBirthdate = e => {
    setBirthDate(e.target.value);
  };

  const handleChangeEmail = e => {
    setEmailAddress(e.target.value);
  };

  const handleAddEmail = () => {
    if (emailRegex.test(emailAddress)) {
      dispatch(setEmail(emailAddress));
      dispatch(openAdditionModal({ type: 'email' }));
    } else {
      dispatch(setNotifications({ type: 'error', message: 'EMAIL_IS_NOT_CORRECT' }));
    }
  };

  return (
    <>
      <PageMeta />
      <ModalOverlay>
        <div className={styles.modal}>
          <ModalHeader title='settings' />
          <div className={styles.settings}>
            <ThemeSwitcher />
            <div className={styles.data}>
              {getIsFlagEnabled('EMAIL_ADD') ? (
                <InputWithButton
                  placeholder='usermail@gmail.com'
                  labelText='E-mail'
                  name='email'
                  type='email'
                  value={user?.email || emailAddress}
                  disabled={user?.email || isEmailAdded}
                  buttonText={user?.email || isEmailAdded ? null : 'Добавить'}
                  onChange={handleChangeEmail}
                  onButtonClick={handleAddEmail}
                />
              ) : (
                <InputWithButton
                  placeholder='usermail@gmail.com'
                  labelText='E-mail'
                  name='email'
                  type='email'
                  value={user?.email}
                  disabled={user?.email}
                />
              )}

              <InputWithButton
                isVerified={user?.isPhoneVerified}
                placeholder='Добавить номер'
                labelText='Номер телефона'
                name='phone'
                masked
                mask='+7 (000) 000 00 00'
                value={user?.phone}
                disabled={true}
              />
              <InputWithButton
                masked
                mask='00.00.0000'
                placeholder='дд.мм.гггг'
                labelText='Дата рождения'
                buttonText={crmUserData?.dates?.length ? null : 'Добавить'}
                name='dateOfBirth'
                type='date'
                value={crmUserData?.dates?.[0]?.date || birthDate}
                onChange={handleChangeBirthdate}
                onButtonClick={() => accessToken && setBirthdateMutation.mutate(birthDate)}
                disabled={crmUserData?.dates?.length}
              />
            </div>
          </div>
        </div>
      </ModalOverlay>
    </>
  );
};
