import { Bonuses } from '../Bonuses/Bonuses';
import { BonusItemModal } from '../Bonuses/BonusItemModal/BonusItemModal';
import { Deposit } from '../Deposit/Deposit';
import { EmailAdd } from '../EmailAdd/EmailAdd';
import GameModal from '../GameModal/GameModal';
import { ModalContentCategory } from '../GamesModal/ui/ModalContentCategory/ModalContentCategory';
import { ModalContentFilteredGames } from '../GamesModal/ui/ModalContentFilteredGames/ModalContentFilteredGames';
import { ModalContentNovelties } from '../GamesModal/ui/ModalContentNovelties/ModalContentNovelties';
import { ModalContentProviders } from '../GamesModal/ui/ModalContentProviders/ModalContentProviders';
import { ModalContentRecent } from '../GamesModal/ui/ModalContentRecent/ModalContentRecent';
import { Loyalty } from '../Loyalty/Loyalty';
import { Profile } from '../Profile/Profile';
import { Safe } from '../Safe/Safe';
import { Settings } from '../Settings/Settings';
import { SmsCodeModal } from '../WithdrawalCascade/SmsCodeModal/SmsCodeModal';
import { Transactions } from '../Transactions/Transactions';
import { Withdrawal } from '../WithdrawalCascade/Withdrawal/Withdrawal';
import { WithdrawalRequisites } from '../WithdrawalCascade/WithdrawalRequisites/WithdrawalRequisites';
import { WithdrawalRequisitesAdd } from '../WithdrawalCascade/WithdrawalRequisitesAdd/WithdrawalRequisitesAdd';
import { WithdrawalRequisitesDelete } from '../WithdrawalCascade/WithdrawalRequisitesDelete/WithdrawalRequisitesDelete';
import { getIsFlagEnabled } from '../../../../utils/getIsFlagEnabled';

// Функция для параметризованных урлов
const matchRoute = (pattern, url) => {
  const regexPattern = pattern.replace(/\*/g, '.*');
  const regex = new RegExp(`^${regexPattern}$`);
  return regex.test(url);
};

export const MODAL_ROUTES = {
  '/category': {
    component: ModalContentCategory,
    title: 'Категория',
  },
  '/category/*': {
    component: ModalContentFilteredGames,
    title: 'Игры категории',
  },
  '/deposit': {
    component: Deposit,
    title: 'Депозит',
  },
  '/new': {
    component: ModalContentNovelties,
    title: 'Новинки',
  },
  '/profile': {
    component: Profile,
    title: 'Профиль',
  },
  '/profile/bonuses': {
    component: Bonuses,
    title: 'Бонусы',
  },
  '/profile/bonuses/*': {
    component: BonusItemModal,
    title: 'Бонусы',
  },
  '/profile/loyalty': {
    component: Loyalty,
    title: 'Лояльность',
  },
  '/profile/settings': {
    component: Settings,
    title: 'Настройки',
  },
  '/profile/transactions': {
    component: Transactions,
    title: 'История Транзакций',
  },
  '/providers': {
    component: ModalContentProviders,
    title: 'Провайдеры',
  },
  '/providers/*': {
    component: ModalContentFilteredGames,
    title: 'Игры провайдера',
  },
  '/recent': {
    component: ModalContentRecent,
    title: 'Недавние',
  },
  '/safe': {
    component: Safe,
    title: 'Сейф',
  },
  '/withdrawal': {
    component: Withdrawal,
  },
  '/withdrawal-step2': {
    component: WithdrawalRequisites,
  },
  '/withdrawal-step3': {
    component: WithdrawalRequisitesDelete,
  },
  '/withdrawal-step4': {
    component: WithdrawalRequisitesAdd,
  },
  '/withdrawal-step5': {
    component: SmsCodeModal,
  },
  '/email-add': {
    component: EmailAdd,
  },
};

const GAME_MODAL_CONFIG = {
  component: GameModal,
  title: 'Game',
};

export const getModalContent = url => {
  if (url.includes('/game/') && getIsFlagEnabled('GAME_PAGE') === false) {
    return GAME_MODAL_CONFIG;
  }

  const matchedPattern = Object.keys(MODAL_ROUTES).find(pattern => matchRoute(pattern, url));

  return matchedPattern ? MODAL_ROUTES[matchedPattern] : null;
};
