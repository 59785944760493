import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useCrmCall } from '../../../../api/useCrmCall';
import { getRegisterUrl } from '../../../../constants/ssoConstants';
import useDisableScroll from '../../../../hooks/useDisableScroll';
import { usePlatform } from '../../../../hooks/usePlatform';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import styles from './GameModal.module.scss';

const GameModal = () => {
  const { accessToken } = useSelector(store => store.userSlice);

  const location = useLocation();
  const { postCrmRequest } = useCrmCall();
  const { isDesktop } = useWindowSize();

  useDisableScroll();

  const gameKey = location?.pathname?.split('/')?.at(-1);

  // Определение платформы для того, чтобы игра отображалась корректно
  const platform = usePlatform();

  const { data: gameUrl = '' } = useQuery({
    queryKey: ['gameSession', gameKey, platform],
    queryFn: () => postCrmRequest('/games/get_game_session', { gameKey, platform }),
    //TODO: gameKey.includes('_') - костыль чтобы не перезапрашивались данные, надо исправить в будущем!
    enabled: accessToken && gameKey.includes('_'),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  const gameData = useQuery({
    queryKey: ['gameData', gameKey],
    queryFn: () => postCrmRequest('/games/game_by_key', { gameKey }),
    //TODO: gameKey.includes('_') - костыль чтобы не перезапрашивались данные, надо исправить в будущем!
    enabled: accessToken && gameKey.includes('_'),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  useEffect(() => {
    if (!accessToken) {
      window.location = getRegisterUrl();
    }
  }, []);

  return (
    <>
      <ModalOverlay className={styles.overlay} overlayClose={false}>
        <div className={clsx(styles.modal)}>
          {isDesktop && <ModalHeader title={gameData?.data?.game?.translates?.en?.name || ''} />}
          <iframe
            className={clsx(styles.iframe, styles.fullscreen)}
            src={gameUrl}
            title={gameKey}
            style={{
              borderRadius: 0,
            }}
            allowFullScreen={true}
          />
        </div>
      </ModalOverlay>
    </>
  );
};

export default GameModal;
