export const getIsFlagEnabled = flag => {
  try {
    const featureFlagsStr = import.meta.env.VITE_FEATURE_FLAGS;

    if (featureFlagsStr) {
      const featureFlags = JSON.parse(featureFlagsStr);

      return featureFlags?.[flag] || false;
    }
  } catch (error) {
    console.error('Error parsing feature flags:', error);
    return false;
  }
};
