import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { closeAllModals } from '../../../../redux/slices/modalSlice';

export const useWithdrawalClose = closePortal => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return modalType => {
    // Если флоу через старую модалку по прямому урл
    if (pathname === '/withdrawal') {
      navigate('/');
      return dispatch(closeAllModals());
    }

    closePortal(modalType);
  };
};
