import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AirWallet from '../../../../assets/components/Header/AirWallet.svg?react';
import LogoWallet from '../../../../assets/components/Header/LogoWallet.svg?react';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { openCommonModal } from '../../../../redux/slices/commonModalSlice';
import { getIsFlagEnabled } from '../../../../utils/getIsFlagEnabled';
import Skeleton from '../../../atoms/Skeleton/Skeleton';
import styles from './Wallet.module.scss';

export const Wallet = ({ crmUserData, userLoading, balance, type = 'real' }) => {
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpen = () => {
    if (getIsFlagEnabled('NEW_MODALS')) {
      return dispatch(openCommonModal({ type: 'deposit' }));
    }
    return navigate('/deposit');
  };

  if (!isMobile) {
    if (crmUserData === null) {
      return <Skeleton className={styles.skeleton} />;
    }
    return balance === 0 && type === 'air' ? (
      <></>
    ) : (
      <>
        <div className={styles.desktopWrapper} onClick={handleOpen} data-exclude-outside-click>
          <div className={styles.badge}>
            {type === 'air' ? (
              <AirWallet className={styles.walletIcon} />
            ) : (
              <LogoWallet className={styles.walletIcon} />
            )}
          </div>
          <div className={styles.container}>
            <div className={styles.balance}>
              <span>{balance ?? 0}</span>
              <span className={styles.currency}>₽</span>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={styles.wallet} onClick={() => navigate('/deposit')}>
      <div className={styles.icon}>₽</div>
      {userLoading ? <p>Загрузка...</p> : <div className={styles.balance}>{balance ?? 0}</div>}
    </div>
  );
};
