import { useQuery } from '@tanstack/react-query';
import DOMPurify from 'dompurify';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useCrmCall } from '../../../api/useCrmCall';
import ArrowLeftSvg from '../../../assets/components/ArrowLeft/arrowLeft.svg?react';
import ArrowLeftDarkSvg from '../../../assets/components/ArrowLeft/arrowLeftDark.svg?react';
import TribeDark from '../../../assets/components/Header/TribeDark.svg?react';
import TribeLight from '../../../assets/components/Header/TribeLight.svg?react';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { setNotifications } from '../../../redux/slices/notificationsSlice';
import { ThemeContext } from '../../../theme/ThemeContext';
import { convertDateFormat } from '../../../utils/convertDateFormat';
import { replaceImagesDomain } from '../../../utils/replaceImagesDomain';
import Skeleton from '../../atoms/Skeleton/Skeleton';
import { Footer } from '../../organisms/Footer/Footer';
import { Header } from '../../organisms/Header/Header';
import NavBarBottom from '../../organisms/NavBar/NavBarBottom/NavBarBottom';
import NewsSlider from '../../organisms/NewsSlider/NewsSlider';
import styles from './NewsDetailPage.module.scss';

export const NewsDetailPage = () => {
  const { finalTheme } = useContext(ThemeContext);

  const { id } = useParams();
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const { postCrmRequest } = useCrmCall();
  const dispatch = useDispatch();

  const { data: article, error } = useQuery({
    queryKey: ['article', id],
    queryFn: () => postCrmRequest('/news/get_news_by_id', { id }),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  useEffect(() => {
    if (error) {
      dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
    }
  }, [error]);

  useEffect(() => {
    //TODO фикс под ios chrome
    setTimeout(() => {
      window.scroll(-200, -200);
    }, 1);
  }, [id]);

  if (!article) {
    return (
      <>
        <Header />
        <div className={styles.newsDetailPage}>
          <Skeleton className={styles.headerSkeleton} opacity />

          <div className={styles.contentWrapper}>
            <div className={styles.text}>
              <div className={styles.newsFullText}>
                <Skeleton className={styles.textSkeleton} opacity />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className={styles.newsDetailPage}>
        <div className={styles.titleWrapper}>
          {!isMobile && <div className={styles.tribe}>{finalTheme === 'dark' ? <TribeDark /> : <TribeLight />}</div>}
          <div className={styles.backWrapper} onClick={() => navigate(-1)}>
            <div className={styles.titleContainer}>
              {finalTheme === 'dark' ? (
                <ArrowLeftSvg height='100%' width='100%' />
              ) : (
                <ArrowLeftDarkSvg height='100%' width='100%' />
              )}
              {!isMobile && <h1 className={styles.newsTitle}>{article?.local_ru?.title}</h1>}
            </div>
            <div className={styles.box}>
              {isMobile && <h1 className={styles.newsTitle}>{article?.local_ru?.title}</h1>}
              <p className={styles.newsDate}>{convertDateFormat(article?.publishedAt)}</p>
            </div>
          </div>
        </div>

        <div className={styles.contentWrapper}>
          <div className={styles.text}>
            <div
              className={styles.newsFullText}
              dangerouslySetInnerHTML={{
                __html: replaceImagesDomain(DOMPurify.sanitize(article?.local_ru?.full_text)),
              }}
            />
          </div>
        </div>
        <NewsSlider currentNewsId={id} />
      </div>

      <Footer />
      {isMobile && <NavBarBottom />}
    </>
  );
};
