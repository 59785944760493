import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import TribeDarkPng from '../../../assets/components/Header/TribeDark.png';
import TribeLightPng from '../../../assets/components/Header/TribeLight.png';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { closeAllModals, closeCurrentModal } from '../../../redux/slices/modalSlice';
import { clearState } from '../../../redux/slices/paymentSlice';
import { ThemeContext } from '../../../theme/ThemeContext';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';
import { getIsFlagEnabled } from '../../../utils/getIsFlagEnabled';
import { BackButton } from '../../atoms/BackButton/BackButton';
import { CloseButton } from '../../atoms/CloseButton/CloseButton';
import Skeleton from '../../atoms/Skeleton/Skeleton';
import styles from './ModalHeader.module.scss';

const ModalHeader = ({ title, showTitle = true, showBackArrow = true, onBack, closePortal, showClose = true }) => {
  const { finalTheme } = useContext(ThemeContext);
  const modals = useSelector(state => state.modalSlice.modals);
  const { t } = useTranslation('ModalHeader');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mutationRef = useRef();

  const { isMobile } = useWindowSize();

  const handleBack = () => {
    if (getIsFlagEnabled('NEW_MODALS') && onBack) {
      return onBack();
    }

    const currentModal = modals[modals.length - 1];
    const prevModal = modals[modals.length - 2];

    // Если текущая модалка содержит -step
    if (currentModal?.route.includes('-step')) {
      const firstStep = currentModal.route.split('-step')[0];
      // Ищем индекс первой модалки в стеке с базовым маршрутом
      const firstStepIndex = modals.findIndex(modal => modal.route.startsWith(firstStep));

      // Очищаем все модалки после первой
      for (let i = modals.length - 1; i > firstStepIndex; i--) {
        dispatch(closeCurrentModal());
      }

      if (firstStepIndex !== -1) {
        navigate(modals[firstStepIndex].route);
      } else {
        navigate('/');
      }
      return;
    }

    // Стандартная навигация назад
    dispatch(closeCurrentModal());
    if (prevModal) {
      navigate(prevModal.route);
    } else {
      navigate('/');
    }
  };

  const handleClosePortal = () => {
    if (closePortal) {
      return closePortal();
    }

    navigate('/');
    dispatch(closeAllModals());
    dispatch(clearState());
  };

  useEffect(() => {
    const imageElement = mutationRef.current;
    if (imageElement) {
      imageElement.classList.remove(styles.fade);
      setTimeout(() => {
        imageElement.classList.add(styles.fade);
      }, 10);
    }
  }, [finalTheme]);

  return (
    <div className={styles.modalHeader}>
      {showTitle && (showBackArrow ? <BackButton handleGoBack={handleBack} /> : null)}

      {!isMobile && (
        <div className={styles.tribe}>
          <img
            ref={mutationRef}
            style={{ opacity: 0 }}
            src={finalTheme === 'dark' ? TribeDarkPng : TribeLightPng}
            alt={finalTheme === 'dark' ? 'tribe' : 'tribeLight'}
          />
        </div>
      )}

      <div className={styles.headerContentWrapper}>
        {showTitle ? (
          <>
            {title ? (
              <h3> {t(title) || capitalizeFirstLetter(title)}</h3>
            ) : (
              <Skeleton key='skel-title' className={styles.titleSkeleton} />
            )}
          </>
        ) : (
          <div />
        )}
        {showClose && <CloseButton onClick={handleClosePortal} />}
      </div>
    </div>
  );
};

export default ModalHeader;
